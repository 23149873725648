<template lang="html">
  <div class="warning">
    <div class="warning__box">
      <div class="warning__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [

  ]
}
</script>

<style lang="scss" scoped>
  .warning {
    position: relative;

    &__box {
      width: 300px;
      height: 40px;
      background-color: #26B2A1;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      margin-left: auto;
      margin-right: auto;
      top: 100px;
      left: 0;
      right: 0;
      text-align: center;
    }
    &__content {

      p {
        color: #fafafa;
        text-align: center;
      }
    }
  }
</style>
