<template lang="html">
  <div class="pt-20 each__content">
    <div
      class="md:w-768 lg:w-1024 mx-auto pt-12 px-4 md:px-0 flex flex-col justify-center"
    >
        <Success v-if="showWarning">
            <p>
                Successfully sent
            </p>
        </Success>
        <div class="contact__top">
            <h1 class="text-2xl text-primary font-semibold">Contact Us</h1>
            <!-- <img rel="prefetch" src="./assets/img/contactus-bg.png" alt=""> -->
            <p class="text-base text-justify">
            Whether you are a teacher or a school administrator, a business, or a student, we at Nexgen are ready to help you reach your English learning goals, quickly and easily. Contact us to learn more by filling out the form below or sending us an email at info@nexgenenglishonline.co
            </p>
        </div>
        <div class="mt-10">
            <div class="mx-auto mb-4 w-full lg:w-8/12 flex justify-start items-center" v-if="errors.length">
              <span class="text-red" v-for="(error, i) in errors" :key="i">{{ error }}</span>
            </div>
            <div class="">
                <form action="#">
                    <ul class="flex flex-col justify-center items-center">
                        <li class="w-full lg:w-8/12 flex flex-col md:flex-row justify-between">
                            <div class="md:w-6/12 flex items-center justify-between">
                                <label for="first_name">First Name*</label>
                                <input class="w-7/12 ml-5 md:ml-10 px-2 py-1 border border-secondary" type="text" v-model="first_name" placeholder="John" />
                            </div>
                            <div class="md:w-6/12 ml-0 md:ml-2 mt-2 md:mt-0 md:box-border flex items-center justify-between">
                                <label class="md:pl-6" for="first_name">Last Name*</label>
                                <input class="w-7/12 ml-5 px-2 py-1 border border-secondary" type="text" v-model="last_name" placeholder="Smith" />
                            </div>
                        </li>
                        <li class="w-full lg:w-8/12 my-2 md:my-6 flex flex-col md:flex-row justify-between">
                          <div class="md:w-6/12 flex items-center justify-between">
                            <label for="email">Email*</label>
                            <input class="w-7/12 ml-5 md:ml-10 px-2 py-1 border border-secondary" type="email" v-model="email" placeholder="john.smith@gmail.com" />
                          </div>
                          <div class="md:w-6/12 ml-0 md:ml-2 mt-2 md:mt-0 md:box-border flex items-center justify-between">
                          <label class="md:pl-6" for="country">Country</label>
                            <select name="country" id="country" class="w-7/12 ml-5 md:ml-10 px-2 py-1 border border-secondary bg-white" v-model="country">
                            <option value="select" disabled>Select Country</option>
                              <option v-for="(data, i) in countryList" :value="data.name">{{data.name}}</option>
                            </select>
                          </div>
                        </li>
                        <li class="w-full lg:w-8/12 flex justify-between">
                            <label class="md:mr-16" for="comments">Message*</label>
                            <textarea cols="52" rows="3" v-model="message" class="w-7/12 md:w-10/12 px-2 ml-14 md:ml-2 border border-secondary"></textarea>
                        </li>
                        <li class="w-full md:w-3/12 mt-2 mb-4 md:my-6 flex items-center justify-between">
                          <label for="my-dropdown">I am a:</label>
                          <select id="my-dropdown" v-model="role" class="w-7/12 md:w-8/12 px-2 py-1 border border-secondary bg-white">
                            <option value="select" disabled>Select</option>
                            <option value="k12Teacher">K-12 Teacher</option>
                            <option value="UniversityProfessor">University Professor</option>
                            <option value="SchoolAdministrator">School Administrator</option>
                            <option value="UniversityAdministrator">University Administrator</option>
                            <option value="HR/BusinessManager">HR/Business Manager</option>
                            <option value="Student">Student</option>
                            <option value="Nurse">Nurse</option>
                            <option value="Other">Other</option>
                          </select>
                        </li>
                        <li class="w-full lg:w-8/12 mt-4 flex items-center justify-center">
                            <div class="w-6/12 md:w-3/12 text-center px-2 py-1 cursor-pointer bg-secondary text-white hover:bg-softGrey hover:text-secondary transition-all" @click="postContact" v-if="!showLoader">
                            Send
                            </div>
                            <div class="loader" v-if="showLoader">
                            <Loader />
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
            <div class="w-8/12 md:w-4/12 lg:w-3/12 mt-16 mb-4 mx-auto">
              <div class="flex justify-center items-center">
                <a href="https://www.linkedin.com/company/18831449/admin/" target="_blank" class="flex font-semibold text-primary">
                  <!-- <LinkedinIconVue height="26" width="26" class="mr-2" /> -->
                  Follow us on 
                  <img class="h-6 ml-2" src="@/assets/images/icons/LinkedIn-Blue-14@2x.png" alt="LinkedIn-Blue-14@2x">
                </a>
                <!-- <a href="mailto:support@nexgenenglishonline.co" class="flex font-semibold text-primary">
                  <SupportIconVue height="32" width="32" />
                  Support
                </a> -->
              </div>
                <!-- <ul>
                    <li>
                    <h1 class="tittle-h4">Email Us</h1>
                    </li>
                    <li><a href="mailto:support@nexgenenglishonline.co" style="text-decoration: underline;transition: all .2s;">support@nexgenenglishonline.co</a></li>
                </ul>
                <ul>
                    <li>
                    <h1 class="tittle-h4">Support</h1>
                    </li>
                    <li>If you have more questions or would like more information please click on our support link below</li>
                </ul>
                <ul>
                    <li>
                    <h1 class="tittle-h4">LinkedIn</h1>
                    </li>
                    <li>Visit us on <a href="https://www.linkedin.com/company/18831449/admin/" target="_blank" style="text-decoration: underline;transition: all .2s;">LinkedIn</a></li>
                </ul> -->
            </div>
        </div>     
    </div>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import Success from "../components/warning/Success";
import Loader from "../components/loader/Loader";
import CountryList from "@/assets/json/CountryList.json";

import LinkedinIconVue from "../components/svgicons/LinkedinIcon.vue";
import SupportIconVue from "../components/svgicons/SupportIcon.vue";

export default {
  components: {
    Success,
    Loader,
    LinkedinIconVue,
    SupportIconVue,
  },
  data() {
    return {
      countryList: CountryList,
      first_name: "",
      last_name: "",
      email: "",
      message: "",
      role: "select",
      country: "select",
      errors: [],
      showLoader: false,
      showWarning: false,
    };
  },
  computed: {
    ...mapGetters("modulePostEmail", {
      getEmail: "getEmail",
    }),
  },
  methods: {
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    postContact() {
      const data = {
        via: ["mail"],
        template: "NCWContactForm",
        contents: {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          message: this.message,
          country: this.country,
          role: this.role,
        },
        mail: {
          subject: "New Message from NCW Contact Us Form",
          to: [
            // {
            //   email: "fditya@dyned.com",
            //   name: "neo Support",
            //   type: "to",
            // },
            // {
            //   email: "userdyned.2@gmail.com",
            //   name: "neo Support",
            //   type: "cc",
            // },
            {
              email: "info@nexgenenglishonline.co",
              name: "neo Support",
              type: "to",
            },
            {
              email: "info@nexgenenglishonline.co",
              name: "neo Support",
              type: "cc",
            },
            // {
            //   email: "dtipping@dyned.com",
            //   name: "David Tipping",
            //   type: "cc",
            // },
          ],
        },
      };
      // console.log(data);
      this.errors = [];
      if (!this.first_name) {
        this.errors.push("First name required.");
      } else if (!this.last_name) {
        this.errors.push("Last name required.");
      } else if (!this.email) {
        this.errors.push("Email required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      } else if (!this.message) {
        this.errors.push("Message required.");
      } else {
        this.showLoader = true;
        this.$store.dispatch("modulePostEmail/postMail", data);
      }
    },
  },
  watch: {
    getEmail(i) {
      if (i == true) {
        this.first_name = "";
        this.last_name = "";
        this.email = "";
        this.message = "";
        this.role = "select";
        this.country = "select";
        this.showLoader = false;
        this.showWarning = true;
        setTimeout(() => {
          this.showWarning = false;
          this.$store.commit("modulePostEmail/muttEmail", null);
        }, 3000);
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
